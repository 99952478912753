import React from "react";

// types
interface Error {
    stack?: string;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component {
    state: ErrorBoundaryState = {
        hasError: false,
    };

    static getDerivedStateFromError(_: Error) {
        let ns: ErrorBoundaryState = {hasError: true};
        return ns;
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <h1>500 Internal Server Error</h1>;
        }
        return this.props.children;
    }
}

interface LayoutProps {
    children: React.ReactNode
}

const Layout = ({children}: LayoutProps) => {
    return (
        <ErrorBoundary>
            {children}
        </ErrorBoundary>
    )
}

export default Layout;
